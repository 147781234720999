<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Departments
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Keresés"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="collection"
        :search="search"
        :sort-by="['id']"
        sort-desc="true"
        :footer-props="{
          'items-per-page-options': [100],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-spacer></v-spacer> -->
            <DepartmentModalForm
              :modalData="modalData"
              :statuses="statuses"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
            ></DepartmentModalForm>
            <v-spacer></v-spacer>
            <v-checkbox
              v-model="showAll"
              label="Active departments"
            ></v-checkbox>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link
            :to="`/companysettings/departments/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link>
          <v-icon
            color="primary"
            small
            @click="deleteItem(item)"
            v-if="permissionCan('delete')"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";
import DepartmentModalForm, { initialFormData } from "./DepartmentModalForm";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_DEPARTMENT,
  DEPARTMENT_COLLECTION,
} from "@/core/services/store/department.module.js";

export default {
  name: "Department",
  components: { DepartmentModalForm },
  data() {
    return {
      showAll: false,
      search: "",
      collection: [],
      permissions: [],
      headers: [
        {
          text: "Department",
          value: "name",
        },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedIndex: -1,
        editedItem: initialFormData,
      },

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([DEPARTMENT_COLLECTION, "statuses"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    showAll() {
      if (this[DEPARTMENT_COLLECTION].length > 0) {
        this.filterCollection(this[DEPARTMENT_COLLECTION]);
      }
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    departmentCollection(val) {
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions([FETCH_DEPARTMENT]),
    editItem(id) {
      ApiService.get("companySetting/department/" + id)
        .then(({ data }) => {
          this.modalData.editedIndex = 1;
          this.modalData.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this[DEPARTMENT_COLLECTION].indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete("companySetting/department/" + this.editedItem.id)
        .then(() => {
          this.fetchDepartment();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      if (this.$route.name !== "departments")
        this.$router.push({ name: "departments" });
      // this.$router.push({ name: "departments" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      /**Delete unused translations */
      i18nService.languages.forEach((item) => {
        let langCode = item.lang;
        if (
          model.translations[langCode] &&
          model.translations[langCode].name == ""
        ) {
          delete model.translations[langCode];
        }
      });
      /**Delete unused translations */

      if (this.modalData.editedIndex > -1) {
        ApiService.put("companySetting/department/" + model.id, model)
          .then(() => {
            this.fetchDepartment();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      } else {
        //create model
        ApiService.post("companySetting/department", model)
          .then(({ data }) => {
            console.log(data);
            this.fetchDepartment();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "companySettings.department." + action
      );
    },
    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("department") > -1
        );
      }
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "department" }]);
    this.fetchDepartment();
    this.setPermissions();
  },
};
</script>
